@use "./palette";
@use '@angular/material' as mat;
@include mat.core();

$font: var(--font);
$fontConfig: mat.define-typography-config($headline-1: mat.define-typography-level(112px, 112px, 300, $font, -0.0134em),
    $headline-5: mat.define-typography-level(56px, 56px, 400, $font, -0.0089em),
    $headline-6: mat.define-typography-level(45px, 48px, 400, $font, 0em),
    $subtitle-2: mat.define-typography-level(16px, 28px, 400, $font, 0.0094em),
    $subtitle-1: mat.define-typography-level(15px, 24px, 500, $font, 0.0067em),
    $body-2: mat.define-typography-level(14px, 24px, 500, $font, 0.0179em),
    $body-1: mat.define-typography-level(14px, 20px, 400, $font, 0.0179em),
    $button: mat.define-typography-level(14px, 14px, 500, $font, 0.0893em),
    $caption: mat.define-typography-level(12px, 20px, 400, $font, 0.0333em),
  );

.main-container {
  --text-primary-color: #{white};
  --text-primary-lighter-color: #{rgba(black, 0.87)};
  --text-primary-darker-color: #{white};
  --text-accent-color: #{white};
  --text-accent-lighter-color: #{rgba(black, 0.87)};
  --text-accent-darker-color: #{white};

  --text-warn-color: #{white};
  --text-warn-lighter-color: #{rgba(black, 0.87)};
  --text-warn-darker-color: #{white};
}


$theme-primary: mat.define-palette($base-palette, 500);
$theme-accent: mat.define-palette($base-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$theme-warn: mat.define-palette($red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$theme: mat.define-light-theme((color: (primary: $theme-primary,
        accent: $theme-accent,
        warn: $theme-warn,
      )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($theme);

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 50%;
}

.mat-mdc-dialog-container .mdc-dialog__content {
  font-family: Roobert, RoobertENEL-Light, sans-serif !important;
  font-size: 1.4rem !important;
}

mat-form-field.mat-mdc-form-field {
  font-size: 1.4rem;
  line-height: 1.6rem;
}

// COM Table checkbox
.mdc-form-field>label {
  padding-left: 0 !important;
}

.mat-expansion-panel-header {

  &-description,
  &-title {
    flex-flow: row;
    justify-content: space-between;
  }

  .mat-content {
    display: inline-block;
    width: 100%;
  }
}


.mat-expansion-panel .mat-expansion-panel-header.mat-expansion-toggle-indicator-before {
  align-items: flex-start;
  padding: 1.5rem 1.9rem 1.5rem 2.4rem;

  .mat-expansion-indicator {
    margin: -0.2rem 1.6rem 0 0;
    display: inline-block;
    vertical-align: top;

    &::after {
      content: '\231F';
      display: inline-block;
      transform: rotate(45deg);
      vertical-align: top;
      font-size: 3rem;
      line-height: 3rem;
      border: none;
    }
  }
}

.mat-form-field-disabled .mat-form-field-outline {
  background: #f6f6f6;
}

.round-button {
  border-radius: 4rem !important; // doesn't work without the !important
}

// Small height for all components with this class
.small-height {
  @include mat.all-component-densities(-3);
}

// Set any mat-form-field as invalid (border red)
.mat-form-field-invalid {

  .mdc-notched-outline__leading,
  .mdc-notched-outline__notch,
  .mdc-notched-outline__trailing {
    border-color: red !important;
  }
}

// Set any mat-checkbox as invalid (border red)
.mat-checkbox-invalid {
  .mdc-checkbox__background {
    border-color: red !important;
  }
}

// Set any selected mat-button-toggle button as invalid
.mat-button-toggle-invalid {
  .mat-button-toggle-checked button {
    border: .1rem solid red !important;
  }
}

// Different designs dor a button-toggle-group ... different border style and heights
.toggle-group-design {

  .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
  .mat-button-toggle-group-appearance-standard {
    border: none;
  }

  .mat-button-toggle button {
    border-radius: 0.4rem;
    border: none;
    font-weight: 500;
    cursor: pointer;
    background-color: $brand-light-gray;
  }

  .mat-button-toggle-checked button {
    cursor: pointer;
    border: .2rem solid $brand-primary;
    color: $brand-primary !important;
  }

  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 3rem;
  }

  .mat-button-toggle-appearance-standard {
    background-color: $brand-light-gray;
  }

  &-border {
    .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
      line-height: 2.5rem;
    }

    .mat-button-toggle button {
      margin: .35rem;
      width: auto;
    }

    .mat-button-toggle-checked button {
      margin: .25rem;
    }
  }
}

// Remove the header and controls padding and margin top for better fit in design (used in export-report dialog)
.no-header-controls {
  .mat-calendar-header {
    padding-top: 0 !important;
  }

  .mat-calendar-controls {
    margin-top: 0 !important;
  }

  .mat-calendar-table {
    background-color: transparent !important;
  }
}

// set auto-height to any mat-dialog-content so the scroll will not appear (used in esport-report dialog)
.auto-height {
  max-height: initial !important;
}

// Removes the up/down arrows for any input
.input-number-no-arrows::-webkit-outer-spin-button,
.input-number-no-arrows::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
}

// To enable the functionality of removing the calendar inside export-report popup when click outside.
.cdk-overlay-backdrop {
  z-index: 1000 !important;
}

// To include 2 icons at the end in a form field -> used in export-report dialog
.form-field-2-icons {
  height: 3.6rem;
  width: 9.7rem;

  mat-datepicker-toggle {
    margin-right: 0;
    margin-left: 2rem;
  }

  button {
    background-color: $brand-light-gray;
    height: 3.6rem;
    width: initial;
  }
}

// Entire Week Dropdown selection
.mat-mdc-select-value {
  color: $brand-dialog-primary-text;
  font-size: 1.6rem;
  line-height: 1.6rem;
}


.mat-button-toggle-checked {
  background-color: transparent;
}


.checkbox-container--readonly {
  .mdc-checkbox__background {
    border: .2rem solid $brand-checkbox-grey-readonly !important;
    border-radius: .5rem;
    height: 1.6rem;
    width: 1.6rem;
  }
}

.checkbox-container--header.mat-mdc-checkbox {
  margin-left: -1.5rem !important;
}


.checkbox-container--edit {
  .mdc-checkbox__background {
    border: .2rem solid $brand-gray !important;
    border-radius: .5rem;
    height: 1.6rem;
    width: 1.6rem;
  }
}

// Tab Toggle Button Css
.toggle-group-design .mat-button-toggle button {
  color: $brand-table-black;
}

.bulk-selection_container--toggle .mat-button-toggle-group-appearance-standard {
  border: .1rem solid $brand-border;
  border-radius: 0.8rem;
}

// Opt out universal color
.mat-mdc-checkbox label {
  color: $brand-table-black;
}

.export-date {
  .form-field-width {
    width: 24rem;
  }
}


.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-checkmark-color: var(--secondary-text-color) !important;
}

button.feedback-button {
  border-color: rgb(70, 30, 125);
  color: rgb(70, 30, 125);
  display: block;
  margin: 0 auto;
  margin-bottom: 30px;
  font-family: Roobert, RoobertENEL-Light, sans-serif;
  letter-spacing: 0px;
  outline-color: rgb(70, 30, 125);
  width: 120px;

  mat-icon.mat-icon {
    transform: scale(2.1);
    padding-top: 2px;
    padding-left: 2px;
    margin-right: 10px;
    width: 13px;
    color: rgb(87, 56, 255);
  }
}

.mat-date-range-input-inner[disabled] {
  --mat-datepicker-range-input-disabled-state-text-color: #000;
}

.mat-date-range-input-inner[disabled]:disabled,
.mat-date-range-input-inner[disabled]:disabled:hover,
.mat-date-range-input-inner[disabled]:disabled:focus,
.mat-date-range-input-inner[disabled]:disabled:active {
  opacity: 1;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: var(--primary-lighter-color) !important;
  color: var(--secondary-text-color);
}

.mat-calendar-body-selected {
  background-color: $brand-primary !important;
}

menu-control>mat-icon {
  color: white;
}

mat-icon {
  float: none !important;
}